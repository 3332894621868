import React, { useEffect, useState } from 'react';
import {
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'; //, FormikProps
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
// Material-UI
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  CREATE_THEME,
  DELETE_THEME,
  GET_THEMES,
  ThemeData,
  ThemeInput,
} from '../../graphql/queries/themes';
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import WebFont from 'webfontloader';
import { ThemeDesignerStepper } from './ThemeDesignerStepper';
import { ThemePreview } from './ThemePreview';
import { DangerButton } from '../../components/FormElements/FormButtons/DangerButton';
import { Input } from '../../components/FormElements/Input';
import SnackbarAlert, { iSnackbarAlert } from 'components/SnackbarAlert';

interface Props {}

/**
 * Handles theme design for the eCommerce site
 */
const ThemeBuilder: React.FC<Props> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [saveSuccessOpen, setSaveSuccessOpen] = useState<boolean>(false);
  const { data, loading, error } = useQuery<ThemeData>(GET_THEMES);
  const [stepHasErrors, setStepHasErrors] = useState<any[]>([0, 0, 0, 0]);
  const [colorErrors, setColorErrors] = useState<number>(0);
  const [fontErrors, setFontErrors] = useState<number>(0);
  const updateColorErrors = (numErrors: number) => setColorErrors(numErrors);
  const updateFontErrors = (numErrors: number) => setFontErrors(numErrors);
  const [resetStepper, setResetStepper] = useState<number>(0);
	const [message, setMessage] = useState<iSnackbarAlert>({ open: false });

  useEffect(() => {
    if (data && data.getFonts && !fontsLoaded) {
      const families = data.getFonts.map((font: any) => font.family);
      WebFont.load({
        google: {
          families: families,
          text: 'abcdefghijlkmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
        },
      });
      setFontsLoaded(true);
    }
  }, [data, fontsLoaded]);

  const [createTheme, createThemeProps] = useMutation<{
    themeInput: ThemeInput;
  }>(CREATE_THEME, {
    refetchQueries: [{ query: GET_THEMES }],
    awaitRefetchQueries: true,
  });
  const [deleteTheme] = useMutation(DELETE_THEME, {
    refetchQueries: [{ query: GET_THEMES }],
    awaitRefetchQueries: true,
  });

  /**
   * Handles submitting the form whether its a create or update
   * @param props Props passed by formik instance
   * @param isUpdate If its an update, pass ID along with payload
   */
  const handleSubmit = async (
    props: FormikProps<ThemeInput>,
    isUpdate: boolean
  ) => {
    props
      .validateForm(Object.assign({}, props.values, { isUpdate }))
      .then((errors: FormikErrors<ThemeInput>) => {
        // To correctly display error message for the theme name
        // when saving / updating the theme we need to set it to touched.

        props.setFieldTouched('themeName', true, false);
        if (!Object.keys(errors).length) {
          const themeInput = {
            ...props.values,
            fontFamily: props.values.fontFamily,
            themeId: isUpdate ? props.values.baseTheme : null, //Only pass ID in if its an update
            defaultTheme: false,
          }; 
          delete themeInput.baseTheme;
          // @ts-ignore
          delete themeInput.__typename;

          createTheme({
            variables: {
              themeInput: {
                ...themeInput,
              },
            },
          }).then((res: any) => {
            // Preserve the selected theme after save
            let selectedThemeId = props.values.baseTheme;
            props.resetForm();
            if (selectedThemeId) {
              props.setValues(
                {
                  ...res.data.createNewTheme,
                  baseTheme: res.data.createNewTheme.themeId,
                },
                true
              );
            }
            setMessage({ open: true, severity: 'success', message: 'Updated successfully' });
            setResetStepper(resetStepper + 1);
            setSaveSuccessOpen(true);
            handleSaveCloseDialog(props);
          }).catch(() => {
            setMessage({ open: true, severity: 'error', message: 'Something went wrong. Update not successful.' });		
          });
        } else {
          handleErrors(errors);
        }
      });
  };

  /**
   * Manually handles the errors for where the errors exist in the stepper, so we can flag a section as 'has errors'
   * @param errors
   */
  const handleErrors = (errors: FormikErrors<ThemeInput>) => {
    const steps = {
      colors: [
        'colorPrimary',
        'colorSecondary',
        'textPrimary',
        'textSecondary',
        'error',
        'success',
        'warning',
        'info',
        'background',
        'panelBackground',
      ],
      fonts: [
        'fontFamily',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'body1',
        'body2',
      ],
      breakPoints: [
        'xsBreakpoint',
        'smBreakpoint',
        'mdBreakpoint',
        'lgBreakpoint',
        'xlBreakpoint',
      ],
    };
    let colorErrorsTemp = 0;
    let fontErrorsTemp = 0;
    let breakpointErrors = 0;
    Object.keys(errors).forEach((error) => {
      if (steps.colors.includes(error)) {
        colorErrorsTemp++;
      } else if (steps.fonts.includes(error)) {
        fontErrorsTemp++;
      } else if (steps.breakPoints.includes(error)) {
        breakpointErrors++;
      }
    });
    setColorErrors(colorErrorsTemp);
    setFontErrors(fontErrorsTemp);
  };

  /**
   *
   * @param props formik props
   * @param selectedBaseThemeId Id of the theme being selected
   */
  const handleBaseThemeChange = (
    props: FormikProps<ThemeInput>,
    selectedBaseThemeId: string
  ) => {
    if (selectedBaseThemeId === '0') {
      //This value is for 'no base theme' which means we reset form
      props.resetForm();
    }
    const selectedTheme = data?.themeListList.find(
      (theme) => theme.themeId === selectedBaseThemeId
    ); //Mostly for typescript to stop whining
    if (!selectedTheme) {
      return;
    }

    props.setValues(
      {
        ...selectedTheme,
        baseTheme: selectedTheme.themeId!,
      },
      true
    );
  };

  const handleSuccessSnackbarClose = () => setSaveSuccessOpen(false);
  /**
   * Opens the save dialog
   */
  const handleSaveOpenDialog = () => setSaveOpen(true);

  const handleSaveCloseDialog = (props:any) => {
    setSaveOpen(false);
    props.setErrors({});
  }
  /**
   * Opens confirmation dialog
   */
  const handleConfirmationOpen = () => setConfirmOpen(true);
  /**
   * Closes confirmation dialog
   */
  const handleCloseConfirmDialog = () => setConfirmOpen(false);
  /**
   * Handles the delete of the selected theme
   * @param props
   */
  const handleDelete = (props: FormikProps<ThemeInput>) => {
    deleteTheme({
      variables: {
        themeInput: {
          themeId: props.values.baseTheme,
        },
      },
    }).then(() => {
      props.resetForm();
      setConfirmOpen(false);
    });
  };
  let initialValues: ThemeInput = {
    background: '',
    body1: 0,
    body1Font: '',
    body2: 0,
    body2Font: '',
    error: '',
    h1: 0,
    h1Font: '',
    h2: 0,
    h2Font: '',
    h3: 0,
    h3Font: '',
    h4: 0,
    h4Font: '',
    h5: 0,
    h5Font: '',
    h6: 0,
    h6Font: '',
    buttonFont: '',
    button: 0,
    info: '',
    panelBackground: '',
    success: '',
    warning: '',
    colorPrimary: '',
    colorSecondary: '',
    textPrimary: '',
    textSecondary: '',
    themeName: '',
    baseTheme: '0',
    newOrExisting: '0',
    defaultTheme: false,
    fontFamily: '',
  };
  const hexValueRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  const createValidationSchema = (isUpdate: boolean = false) => {
    let themeNameValidationRule;
    if (isUpdate) {
      themeNameValidationRule = Yup.string().required('Required');
    } else {
      themeNameValidationRule = Yup.string()
        .required('Required')
        .notOneOf(
          data?.themeListList.map((theme: any) => theme.themeName) || [],
          'Theme of this name exists, please choose a new one'
        );
    }

    let formSchema = Yup.object<any>().shape({
      colorPrimary: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      colorSecondary: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      textPrimary: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      textSecondary: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      error: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      warning: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      info: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      success: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      background: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      panelBackground: Yup.string()
        .required('Required')
        .matches(hexValueRegex, 'Invalid HEX Code'),
      fontFamily: Yup.string().required('Required'),
      h1: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      h2: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      h3: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      h4: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      h5: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      h6: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      body1: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      body2: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      button: Yup.number()
        .required('Required')
        .min(0.1, 'Font size must be greater than 0.1'),
      themeName: themeNameValidationRule,
    });

    return formSchema;
  };

  const validateFn = (formData: any) => {
    const schema = createValidationSchema(formData.isUpdate);
    delete formData.isUpdate;
    try {
      validateYupSchema(formData, schema, true);
    } catch (error) {
      return yupToFormErrors(error);
    }

    return {};
  };

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Backdrop
            open={createThemeProps?.loading}
            className={classes.backdrop}
          >
            <CircularProgress />
          </Backdrop>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSaveOpenDialog}
            validate={validateFn}
          >
            {(props: FormikProps<ThemeInput>) => (
              <Form noValidate autoComplete="off">
                <ThemeDesignerStepper
                  formikProps={props}
                  handleBaseThemeChange={handleBaseThemeChange}
                  themeList={data!.themeListList}
                  fonts={data!.getFonts}
                  handleDeleteTheme={handleConfirmationOpen}
                  handleSave={handleSubmit}
                  stepHasErrors={stepHasErrors}
                  colorErrors={colorErrors}
                  fontErrors={fontErrors}
                  updateColorErrors={updateColorErrors}
                  updateFontErrors={updateFontErrors}
                  resetListener={resetStepper}
                  saveChanges={handleSaveOpenDialog}
                />
                <ThemePreview formikProps={props} />
                <Dialog
                  fullScreen={fullScreen}
                  open={saveOpen}
                  onClose={() => handleSaveCloseDialog(props)}
                  aria-labelledby="saveThemeDialog"
                >
                  <DialogTitle id="saveThemeDialog">{'Save Theme'}</DialogTitle>
                  <DialogContent className={classes.confirmDialog}>
                    <DialogContentText>
                      <Box display={'block'} m={2}>
                        <Input
                          variant={'outlined'}
                          name={'themeName'}
                          label={'Theme Name'}
                        />
                      </Box>

                      {props.values.defaultTheme
                        ? 'The selected theme is a default theme and cannot be overwritten. You can only save this custom theme as a copy'
                        : 'Save theme as a copy or update existing theme?'}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => handleSubmit(props, false)}
                      color="primary"
                      disabled={props.values.baseTheme === '0'}
                    >
                      Save as Copy
                    </Button>
                    <Button
                      onClick={() => handleSubmit(props, true)}
                      color="primary"
                      disabled={props.values.defaultTheme}
                    >
                      Update Theme
                    </Button>
                    <DangerButton onClick={() => handleSaveCloseDialog(props)}>
                      Cancel
                    </DangerButton>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullScreen={fullScreen}
                  open={confirmOpen}
                  onClose={handleCloseConfirmDialog}
                  aria-labelledby="deleteThemeDialog"
                >
                  <DialogTitle id="deleteThemeDialog">
                    {'Delete Theme'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you wish to delete this theme (this action
                      cannot be reversed)
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={handleCloseConfirmDialog}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => handleDelete(props)}
                      color="inherit"
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            )}
          </Formik>
          <SnackbarAlert open={message.open} severity={message.severity} message={message.message} handleClose={() => setMessage({...message, open: false })}/>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  controlButton: {
    marginRight: '1rem',
  },
  deleteButton: {
    marginRight: '1rem',
    backgroundColor: theme.palette.error.main,
    color: 'black',
  },
  buttonGroupLeft: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.appBar + 1000,
  },confirmDialog: {
    minWidth:'450px',
    height:'190px'
  }
}));

export default ThemeBuilder;
