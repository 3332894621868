import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
    children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
    const classes = useStyles();

    return (
        <>
            {/* <Container className={classes.root} maxWidth={'lg'}> */}
                <main className={classes.main}>{children}</main>
            {/* </Container> */}
        </>
    );
};


const useStyles = makeStyles((theme: Theme) => ({
	main: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default AuthLayout;
