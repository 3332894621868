import React from 'react';
import {Button, ButtonProps, createStyles, PropTypes, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


interface Props extends ButtonProps {
}

export const DangerButton:React.FC<Props> = ({children ,  ...rest}) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            contained: {
                background: theme.palette.error.main,
                color: theme.palette.getContrastText(theme.palette.error.main),
                '&:hover': {
                    background: theme.palette.error.dark
                }
            },
            outlined: {
                border: `1px solid ${theme.palette.error.main}`,
                color: theme.palette.error.main
            },
            text: {
                color: theme.palette.error.main
            }
        }),
    );
    const classes = useStyles();
    return (
        <Button
            {...rest}
            color={"inherit"}
            classes={{
                outlined: classes.outlined,
                contained: classes.contained,
                text: classes.text
            }}
        >{children}</Button>
    )
}