import React, {useEffect} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, Box,
    Button,
    createStyles,
    Drawer,
    Theme,
    Typography
} from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from "@material-ui/core/styles";
import {Input} from "../../components/FormElements/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {ColorThemeCustomisation} from "./ColorThemeCustomisation";
import {FontThemeCustomisation} from "./FontThemeCustomization";
import {Font, ThemeInput} from "../../graphql/queries/themes";
import {BreakpointThemeCustomisation} from "./BreakpointThemeCustomisation";
import {FormikProps} from "formik";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DangerButton} from "../../components/FormElements/FormButtons/DangerButton";
import { useWebsiteList } from 'operations/Website/list';

const drawerWidth = 350;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            zIndex: theme.zIndex.appBar - 1,
            paddingTop: 90
        },
        drawerContainer: {
            overflow: 'auto',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        spacer: {
            marginBottom: theme.spacing(2)
        },
        spacerPreview: {
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        validationError: {
            display: 'flex',
            alignItems: 'center'
        },
        validationWarningIcon: {
            marginRight: theme.spacing(1)
        }
    }),
);

/**
 * Get step labels and controls which step sees what content
 */
function getSteps() {
    return ['Select base theme', 'Select theme colors', 'Select theme fonts', 'Select theme breakpoints (advanced)'];
}

interface Props {
    formikProps: FormikProps<ThemeInput>;
    themeList: ThemeInput[];
    fonts: Font[]
    handleBaseThemeChange: (formikProps: any, value: any) => void;
    handleDeleteTheme: () => void;
    handleSave: (formikProps: FormikProps<ThemeInput>, isCopy: boolean) => void;
    stepHasErrors: any[];
    colorErrors: number;
    fontErrors: number;
    updateColorErrors: (numErrors: number) => void;
    updateFontErrors: (numErrors: number) => void;
    resetListener: number;
    saveChanges: () => void;
}

/**
 * Stepper functionality for the side bar control system
 * @param props props from parent
 */
export const ThemeDesignerStepper: React.FC<Props> = (props) => {
    const {
        formikProps,
        themeList,
        handleBaseThemeChange,
        fonts,
        handleDeleteTheme,
        handleSave,
        stepHasErrors,
        colorErrors,
        fontErrors,
        updateColorErrors,
        updateFontErrors,
        resetListener,
        saveChanges
    } = props;

    const classes = useStyles();

    const {data,loading} = useWebsiteList({search:'Storefront'})
    /**
     * Handles the active step for the stepper
     */
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    /**
     * Checks whether the delete button should be shown
     * The delete button will only show if a theme is non default
     */
    const shouldShowDeleteButton = (): boolean => {
        const themeId = formikProps.values.themeId;
        if (!themeId) {
            return false;
        }
        const theme = themeList.find(theme => theme.themeId === themeId);
        if (!theme) {
            return false;
        }
        return !theme.defaultTheme;
    }

    useEffect(() => {
        if (resetListener > 0) {
            setActiveStep(0);
        }
    }, [resetListener])

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor={"right"}
        >
            <div className={classes.root}>
                <Box p={3}>
                    <Typography className={classes.spacer}>Select an existing theme to base your new theme on. If you do not select a theme,
                    you will be able to create the theme with no base settings</Typography>

                    <Input
                        label={'Theme'}
                        name="baseTheme"
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        select
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBaseThemeChange(formikProps, e.target.value)}
                    >
                        <MenuItem value={'0'}>No Base Theme</MenuItem>
                        {themeList.map((option: any) => (
                            <MenuItem key={option.themeId}
                                      value={option.themeId}>{option.themeName}</MenuItem>
                        ))}
                    </Input>
                    <div className={classes.actionsContainer}>
                        <div>
                            {formikProps.values.baseTheme !== '0' && <Button variant={'contained'} onClick={saveChanges} disabled={(Object.keys(formikProps.errors).length > 1 )}>Save Changes</Button>}
                            {shouldShowDeleteButton() && (
                                <DangerButton variant={'contained'} onClick={handleDeleteTheme}>Delete Theme</DangerButton>)}
                        </div>
                    </div>
                </Box>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                id="colors"
                            >
                                <Typography className={classes.heading}>
                                    Theme Colors
                                    {colorErrors > 0 && <Typography variant={'subtitle1'} color={'error'}
                                                 className={classes.validationError}>
                                        <WarningIcon className={classes.validationWarningIcon}/>
                                        {colorErrors} issue{colorErrors > 1 && 's'}</Typography>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ColorThemeCustomisation formikProps={formikProps} signalError={updateColorErrors}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                id="fonts"
                            >
                                <Typography className={classes.heading}>Fonts
                                    {fontErrors > 0 && <Typography variant={'subtitle1'} color={'error'}
                                                                   className={classes.validationError}>
                                        <WarningIcon className={classes.validationWarningIcon}/>
                                        {fontErrors} issue{fontErrors > 1 && 's'}</Typography>}</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <FontThemeCustomisation fonts={fonts} formikProps={formikProps}
                                                        signalError={updateFontErrors}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                id="breakpoints"
                            >
                                <Typography className={classes.heading}>Breakpoints (Advanced)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BreakpointThemeCustomisation/>
                            </AccordionDetails>
                        </Accordion>
                       {!loading && formikProps.values.baseTheme !== '0' ?
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} marginTop={2} marginBottom={4}>
                                <Typography className={classes.spacerPreview}>Remember to save any changes to the theme before previewing on storefront.</Typography>
                                <a href={`${data.nodes[0].url}?previewTheme=${formikProps.values.baseTheme}`} style={{textDecoration:'none'}} target="_blank">
                                    <Button variant="contained">Preview Theme on Storefront</Button>
                                </a>
                            </Box>
                        :null}
                    </div>
        </Drawer>
)
}