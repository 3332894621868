import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

// Components
import PageHeader from 'components/AppElements/PageHeader';
import SearchBar from 'components/AppElements/SearchBar';
import ErrorRobot from 'components/Layout/ErrorRobot';
import ListPagination from 'components/ListElements/ListPagination';

// Custom-Mui

import Paper from 'customMui/Paper';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import { usePortalOrderDetail } from 'operations/queries/getPortalOrderDetail';
import IconButton from '@material-ui/core/IconButton';
import { useGetOrderDetail } from 'operations/queries/getOrderDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';



interface Props {
	baseUrl: string;
};

const PortalOrderDetail: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();
    const params:any = useParams();
	const breadcrumbs = [
		{ label: 'Portal-Order-Detail' },
	];
	const { data, loading, error } = useGetOrderDetail(params.id);

	const formatCurrency = (price: any) => (isNaN(price) ? '' : `$${parseFloat(price).toFixed(2)}` || 0);

	const handleBack = () => {
		history.goBack()
	};

	if(error)
		return <ErrorRobot/>;


	return (
		<div className={classes.root}>
			<PageHeader
				name="Portal Order Detail"
				maxWidth="md"
				breadcrumbs={breadcrumbs}
			/>

			<Container maxWidth="lg">
				<Paper padding={2}>
					<IconButton onClick={handleBack} >
						<ArrowBackIcon/>
					</IconButton>
                    {!loading ? <Table aria-label="customer table">
						<TableHead>
							<TableRow>
								<TableCell width={200}>Product</TableCell>
								<TableCell width={200}>SKU</TableCell>
								<TableCell width={200}>Quantity</TableCell>
								<TableCell width={200}>Price</TableCell>
								<TableCell width={200}>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(data.lines?.length === 0) ? (
								<TableRow>
									<TableCell colSpan={3} align="center" className="empty">No records found</TableCell>
								</TableRow>
							):data.lines?.map((row:any) => (
								<TableRow key={row.productId}>
									<TableCell>{row.productName}</TableCell>
									<TableCell>{row.productCode}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
									<TableCell>{formatCurrency(row.price)}</TableCell>
									<TableCell> {formatCurrency(
                      							((row?.price || 0) * (row?.quantity || 1)).toFixed(2))}
					  				</TableCell>
								</TableRow>
							))} 
                            
						</TableBody>
					</Table> 
                    : null}
					<Box className={classes.totalsContainer}>
						<Table className={classes.totalsTable}>
						<TableBody>
							<TableRow className={classes.totalsRow}>
							<TableCell className={classes.totalsCell} align="left">
								<Typography variant="body1">{'SubTotal'}</Typography>
							</TableCell>
							<TableCell className={classes.totalsCell} align="right">
								{formatCurrency(data.subTotal)}
							</TableCell>
							</TableRow>
							<TableRow className={classes.totalsRow}>
							<TableCell className={classes.totalsCell} align="left">
								<Typography variant="body1">{'Total'}</Typography>
							</TableCell>
							<TableCell className={classes.totalsCell} align="right">
								<Typography variant="body1">{formatCurrency(data.total)}</Typography>
							</TableCell>
							</TableRow>
						</TableBody>
						</Table>
					</Box>
				</Paper>
			</Container>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingBottom: theme.spacing(10),
	},
	paging: {
		padding: theme.spacing(1),
		display: 'flex',
		justifyContent: 'center',
	},
	totalsContainer: {
		borderTop:'1px solid',
		borderColor: theme.palette.divider,
		display: 'flex',
		justifyContent: 'flex-start',
	},
	totalsTable: {
		[theme.breakpoints.up('sm')]: {
		  maxWidth: '25%',
		},
		marginTop: theme.spacing(1),
	},totalsRow: {
		border: 'none !important',
	},totalsCell: {
		border: 'none !important',
		paddingBottom: theme.spacing(1),
		paddingTop: '0 !important',
	},
}));

export default PortalOrderDetail;
