import React, {useEffect, useState} from 'react';
import {Font, ThemeInput} from "../../graphql/queries/themes";
import FormSection from "../../components/FormElements/FormSection";
import Grid from "@material-ui/core/Grid";
import {FontSelector} from "../../components/FormElements/Input/FontSelector";
import {CustomFontInput} from "../../components/FormElements/Input/CustomFontInput";
import _ from "lodash";
import {Button, Card, CardActions, CardContent, createStyles, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormikProps} from "formik";
import {FontTypeCard} from "./FontTypeCard";
import {Theme} from "@material-ui/core/styles/createMuiTheme";

interface Props {
    fonts: Font[] | undefined
    formikProps: FormikProps<ThemeInput>
    signalError: (numErrors: number) => void;
}

/**
 * Typography display component. Handles the changing of fonts
 * @param props
 * @constructor
 */
export const FontThemeCustomisation:React.FC<Props> = (props) => {
    const {formikProps , signalError} = props;
    const [fontPickerOpen , setFontPickerOpen] = useState<boolean>(false);
    const [typeSelecting , setTypeSelecting] = useState<string | null>(null);
    const [selectedFont , setSelectedFont] = useState<string | null>(null);
    const inputsOnPage = ['fontFamily' , 'h1' , 'h2' , 'h3', 'h4', 'h5', 'h6' , 'body1' , 'body2', 'button'];

    useEffect(() => {
        let errorsOnPageCount = 0;
        Object.keys(formikProps.errors).forEach((key) => {
            if (inputsOnPage.includes(key)) {
                errorsOnPageCount++;
            }
        })
        signalError(errorsOnPageCount);
    }, [inputsOnPage, signalError, formikProps.errors])

    /**
     * Opens the font picker
     * @param selectedFont Font that is already selected (if one exists) to mark as selected in the font picker drwaer
     * @param fontType the type of font we are changing (h1, h2, etc)
     */
    const handleFontPickerOpen = (selectedFont: string | null , fontType: string) => {
        setSelectedFont(selectedFont);
        setTypeSelecting(fontType);
        setFontPickerOpen(true);
    }
    /**
     * Closes font picker
     */
    const handleFontPickerClose = () => setFontPickerOpen(false);

    /**
     * Propagates the selected font from the font picker window to the form value
     * @param family Font family selected
     */
    const handleFontSelect = (family: string) => {
        setFontPickerOpen(false);
        props.formikProps.setFieldValue(typeSelecting! , family);
    }

    /**
     * Clears font if the override is deselected
     * @param fontType Font type we are clearing (h1, h2, etc)
     */
    const handleClearFont = (fontType: string) => {
        props.formikProps.setFieldValue(fontType , '');
    }

    return (
        <Grid container direction={"column"}>
            <Grid item xs={12}>
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'fontFamily'} fontLabel={'Default Font'} selectedFont={props.formikProps.values.fontFamily} isDefault={true} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h1'} fontLabel={'Header 1'} selectedFont={props.formikProps.values.h1Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h2'} fontLabel={'Header 2'} selectedFont={props.formikProps.values.h2Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h3'} fontLabel={'Header 3'} selectedFont={props.formikProps.values.h3Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h4'} fontLabel={'Header 4'} selectedFont={props.formikProps.values.h4Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h5'} fontLabel={'Header 5'} selectedFont={props.formikProps.values.h5Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'h6'} fontLabel={'Header 6'} selectedFont={props.formikProps.values.h6Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'body1'} fontLabel={'Body 1'} selectedFont={props.formikProps.values.body1Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'body2'} fontLabel={'Body 2'} selectedFont={props.formikProps.values.body2Font || null} isDefault={false} openFontPicker={handleFontPickerOpen} />
                <FontTypeCard clearSelectedFont={handleClearFont} fontType={'button'} fontLabel={'Button'} selectedFont={props.formikProps.values.buttonFont || null} isDefault={false} openFontPicker={handleFontPickerOpen} />

            </Grid>


            <FontSelector
                fonts={props.fonts}
                selectedFont={selectedFont}
                drawerWidth={350}
                open={fontPickerOpen}
                handleFontSelect={handleFontSelect}
                handleFontPickerClose={handleFontPickerClose} />

        </Grid>
    )
}