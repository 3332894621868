import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';

// Utils
import useBrowser from "utils/hooks/browser";

// Components
import Loading from 'components/Layout/Loading';

// Routes
import RouteGuard from 'routes/Route';
import ThemeBuilder from '../pages/themes';
import PortalOrderDetail from 'pages/portal-orders/PortalOrderDetail';



const Login = React.lazy(() => import('pages/login'));
// const ForgotPassword = React.lazy(() => import('pages/forgot-password'));
// const ResetPassword = React.lazy(() => import('pages/reset-password'));
const BrowserNotSupported = React.lazy(() => import('pages/browser-not-supported'));
const NotFound = React.lazy(() => import('pages/not-found'));

// Apps
const Home = React.lazy(() => import('pages/home'));
const Studio = React.lazy(() => import('pages/studio'));
const Catalogs = React.lazy(() => import('pages/catalogs'));
const Customers = React.lazy(() => import('pages/customers'));
const MediaLibrary = React.lazy(() => import('pages/media-library'));
const Inventory = React.lazy(() => import('pages/inventory'));
const ResetPassword = React.lazy(() => import('pages/reset-password'));
const ForgotPassword = React.lazy(() => import('pages/forgot-password'));
const Settings = React.lazy(() => import('pages/settings'));
const SettingsGeneral = React.lazy(() => import('pages/settings/general'));
const SettingsProducts = React.lazy(() => import('pages/settings/products'));
const SettingsCustomers = React.lazy(() => import('pages/settings/customers'));
const SettingsOrders = React.lazy(() => import('pages/settings/orders'));
const SettingsPayments = React.lazy(() => import('pages/settings/payments'));
const SettingsCheckout = React.lazy(() => import('pages/settings/checkout'));
const SettingsEmployees = React.lazy(() => import('pages/settings/employees'));
const SettingsRolesAndPermissions = React.lazy(() => import('pages/settings/roles-permissions'));
const SettingsSystem = React.lazy(() => import('pages/settings/system'));
const SettingsSearchEngine = React.lazy(() => import('pages/settings/search-engine'));
const SettingsWebsites = React.lazy(() => import('pages/settings/websites'));
const PortalOrders = React.lazy(() => import('pages/portal-orders'));
const Profile = React.lazy(() => import('pages/profile'));

export default function Routes() {
	const history = useHistory();
	const browser = useBrowser();

	useEffect(() => {
		if(browser !== "Chrome")
			history.replace('browser-not-supported');
	},[browser]);

    return (
		<Suspense fallback={<Loading fullscreen />}>
			<Switch>
				<RouteGuard
					path="/"
					layout="default"
					component={Home}
					exact
					isPrivate={true}
				/>
				<RouteGuard
					path="/studio"
					layout="default"
					component={Studio}
					isPrivate={true}
				/>
				<RouteGuard
					path="/catalogs"
					layout="default"
					component={Catalogs}
					isPrivate={true}
				/>	
				<RouteGuard
					path="/customers"
					layout="default"
					component={Customers}
					isPrivate={true}
				/>
				<RouteGuard
					path="/media-library"
					layout="default"
					component={MediaLibrary}
					isPrivate={true}
				/>
				<RouteGuard
					path="/inventory"
					layout="default"
					component={Inventory}
					isPrivate={true}
				/>
				<RouteGuard
					path="/themes"
					layout="default"
					component={ThemeBuilder}
					exact
					isPrivate={true}
				/>
				<RouteGuard
					path="/profile"
					layout="default"
					component={Profile}
					isPrivate={true}
				/>
				<RouteGuard
					path="/settings"
					layout="default"
					component={Settings}
					exact
					isPrivate={true}
				/>
				<RouteGuard
					path="/portal-orders"
					layout="default"
					component={PortalOrders}
					exact
					isPrivate={true}
				/>
				<RouteGuard
					path="/portal-orders/:id?"
					layout="default"
					component={PortalOrderDetail}
					isPrivate={true}
				/>
					<RouteGuard
						path="/settings/GEN"
						layout="default"
						component={SettingsGeneral}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/PROD"
						layout="default"
						component={SettingsProducts}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/CUST"
						layout="default"
						component={SettingsCustomers}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/ORD"
						layout="default"
						component={SettingsOrders}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/PAY"
						layout="default"
						component={SettingsPayments}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/CHECK"
						layout="default"
						component={SettingsCheckout}
						exact
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/roles-permissions"
						layout="default"
						component={SettingsRolesAndPermissions}
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/search-engine"
						layout="default"
						component={SettingsSearchEngine}
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/employees"
						layout="default"
						component={SettingsEmployees}
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/system"
						layout="default"
						component={SettingsSystem}
						isPrivate={true}
					/>
					<RouteGuard
						path="/settings/websites/:webId?/:name?"
						layout="default"
						component={SettingsWebsites}
						isPrivate={true}
					/>

				<RouteGuard path="/reset-password" layout="auth" component={ResetPassword} />
				<RouteGuard path="/forgot-password" layout="auth" component={ForgotPassword} />
				<RouteGuard path="/login" layout="auth" component={Login} />
				<RouteGuard path="/browser-not-supported" component={BrowserNotSupported} isPrivate={false}></RouteGuard>
				<RouteGuard path="/404" component={NotFound} isPrivate={false}></RouteGuard>
				<Redirect to="/404" />
			</Switch>
		</Suspense>
    );
}
