import React from "react";
import ReactDOM from "react-dom";
import {
	ApolloClient,
	ApolloProvider,
	gql,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	defaultDataIdFromObject,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import "./i18n";

import _concat from 'lodash/concat';
import _get from 'lodash/get';
 
import { getAuthToken, clearToken, getUserFromToken, JwtRefreshLink } from "./utils/auth";

import typePolicies from 'operations/typePolicies';

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FETCH_APP_DATA } from "./graphql/queries/getAppData";

const authLink = setContext((_, { headers }) => {
	const token = getAuthToken();
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: typePolicies
		}
	},
});

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				console.log("error ", err);
			}
			graphQLErrors.map(({ message, locations, path, extensions }) => {
				switch (extensions && extensions.code) {
					case "UNAUTHENTICATED": {
						cache.reset();
						clearToken();
					}
				}
				console.log(
					`[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`
				);
				return `[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`;
			});
		}

		if (networkError) {
			console.log(`[Network Error]: ${networkError}`);
		}
	}
);

/*
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  // uri: 'http://e4cs.net:4000/graphql',
  credentials: "include",
  headers: {
	"client-name": "e4Cloud (web)",
	"client-version": "1.0.0",
  },
});
*/






/**************************************************
***  Start of Custom Fetch for upload progress  ***
***************************************************/

const parseHeaders = (rawHeaders: any) => {
	const headers = new Headers();
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
	preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
		const parts = line.split(":");
		const key = parts.shift().trim();
		if (key) {
			const value = parts.join(":").trim();
			headers.append(key, value);
		}
	});
	return headers;
};

export const uploadFetch = (url: string, options: any) => new Promise((resolve, reject) => {
	const xhr = new XMLHttpRequest();
	xhr.withCredentials = true;

	xhr.onload = () => {
		const opts: any = {
			status: xhr.status,
			statusText: xhr.statusText,
			headers: parseHeaders(xhr.getAllResponseHeaders() || "")
		};
		opts.url =
			"responseURL" in xhr
				? xhr.responseURL
				: opts.headers.get("X-Request-URL");
		
		const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
		resolve(new Response(body, opts));
	};
	xhr.onerror = () => {
		reject(new TypeError("Network request failed"));
	};
	xhr.ontimeout = () => {
		reject(new TypeError("Network request failed"));
	};
	xhr.open(options.method, url, true);

	Object.keys(options.headers).forEach(key => {
		xhr.setRequestHeader(key, options.headers[key]);
	});

	if (xhr.upload) {
		xhr.upload.onprogress = options.onProgress;
	}

	options.onAbortPossible(() => {
		xhr.abort();
	});

	xhr.send(options.body);
});



const customFetch = (uri: any, options: any) => {
	if (options.useUpload) {
		console.log(options);
		return uploadFetch(uri, options);
	}
	return fetch(uri, options);
};




const httpLink = createUploadLink({
	uri: `${process.env.REACT_APP_API_URL}/graphql`,
	credentials: "include",
	headers: {
		"client-name": "studio",
		"client-version": "1.0.0",
	},
	fetch: customFetch as any
});

/************************************************
***  End of Custom Fetch for upload progress  ***
*************************************************/













// const httpLink = createUploadLink({
// 	uri: `${process.env.REACT_APP_API_URL}/graphql`,
// 	credentials: "include",
// 	headers: {
// 		"client-name": "Studio",
// 		"client-version": "1.0.0",
// 	},
// });

const jwtLink = new JwtRefreshLink(() => {
	cache.reset();
});

// Need ts-ignore as the types are not compatible
// @ts-ignore 
const link = ApolloLink.from([jwtLink, authLink, errorLink, httpLink]);

const client = new ApolloClient({
	cache,
	link,
	// typeDefs,
	// resolvers,
});

export const FETCH_SESSION = gql`
  query fetchSession {
    session {
      UserId
    }
  }
`;

async function init() {
	client.writeQuery({
		query: gql`
      query INITIAL_DATA {
        isLoggedIn
        user {
          deletedAt
          email
          emailConfirmed
          exp
          firstName
          iat
          id
          lastName
          userName
        }
        cartItems
        settings
      }
    `,
		data: {
			isLoggedIn: !!getAuthToken(),
			user: getUserFromToken(getAuthToken()),
			cartItems: [],
			settings: {},
		},
	});

	try {
		let result = await client.query({
			query: FETCH_APP_DATA,
		});

		client.writeQuery({
			query: gql`
        query SETTINGS {
          settings
        }
      `,
			data: {
				settings: result.data.settings,
			},
		});

		ReactDOM.render(
			<ApolloProvider client={client}>
				<App isLoggedIn={!!getAuthToken()} settings={result.data.settings} />
			</ApolloProvider>,
			document.getElementById("root")
		);

		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.unregister();
	} catch (error) {
		console.error("error fetching data ", error);
	}
}

init();
