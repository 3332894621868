import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    Paper, Snackbar,
    Theme,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {width: '100%', padding: theme.spacing(2)},
        card: { height: '250px'},
        colorPreview: { height: 100 , width: '100%'},
        primaryColor: { backgroundColor: theme.palette.primary.main},
        secondaryColor: {backgroundColor: theme.palette.secondary.main},
        successColor: {backgroundColor: theme.palette.success.main},
        errorColor: {backgroundColor: theme.palette.error.main},
        infoColor: {backgroundColor: theme.palette.info.main},
        warnColor: {backgroundColor: theme.palette.warning.main}
    }),
);

export const ColorSection: React.FC = () => {
    const classes = useStyles();

    const [successOpen , setSuccessOpen] = useState<boolean>(false);
    const [infoOpen , setInfoOpen] = useState<boolean>(false);
    const [warnOpen , setWarnOpen] = useState<boolean>(false);
    const [errorOpen , setErrorOpen] = useState<boolean>(false);

    const handleSuccessClose = () => setSuccessOpen(false)
    const handleInfoClose = () => setInfoOpen(false)
    const handleWarnClose = () => setWarnOpen(false)
    const handleErrorClose = () => setErrorOpen(false)
    const handleSuccessOpen = () => setSuccessOpen(true)
    const handleInfoOpen = () => setInfoOpen(true)
    const handleWarnOpen = () => setWarnOpen(true)
    const handleErrorOpen = () => setErrorOpen(true)

    return (
        <>
            <Typography variant={'h2'}>
                Theme Colors
            </Typography>
        <Paper className={classes.root}>
            <Grid container spacing={3} wrap={'wrap'} style={{maxWidth: '100%'}}>
                <Grid item xs={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Primary Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.primaryColor}`}/>
                            <Typography variant={'body2'}>
                                Typically your primary brand color
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Secondary Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.secondaryColor}`}/>
                            <Typography variant={'body2'}>
                                Typically your secondary brand color
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Success Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.successColor}`}/>
                            <Typography variant={'body2'}>
                                The color of any notification indicating success
                            </Typography>
                        </CardContent>

                            <Button color={'primary'} onClick={handleSuccessOpen}>Create Success Notification</Button>

                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Information Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.infoColor}`}/>
                            <Typography variant={'body2'}>
                                The color of any notification indicating information
                            </Typography>
                        </CardContent>

                            <Button color={'primary'} onClick={handleInfoOpen}>Create Information Notification</Button>

                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Warning Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.warnColor}`}/>
                            <Typography variant={'body2'}>
                                The color of any notification indicating a warning
                            </Typography>
                        </CardContent>

                            <Button color={'primary'} onClick={handleWarnOpen}>Create Warning Notification</Button>

                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={4} className={classes.card}>
                        <CardHeader title={"Error Color"}/>
                        <CardContent>
                            <div className={`${classes.colorPreview} ${classes.errorColor}`}/>
                            <Typography variant={'body2'}>
                                The color of any notification indicating an error
                            </Typography>
                        </CardContent>

                            <Button color={'primary'} onClick={handleErrorOpen}>Create Error Notification</Button>

                    </Card>
                </Grid>
            </Grid>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    This is a success message!
                </Alert>
            </Snackbar>
            <Snackbar open={infoOpen} autoHideDuration={6000} onClose={handleInfoClose}>
                <Alert onClose={handleInfoClose} severity="info">
                    This is an info message!
                </Alert>
            </Snackbar><Snackbar open={warnOpen} autoHideDuration={6000} onClose={handleWarnClose}>
            <Alert onClose={handleWarnClose} severity="warning">
                This is a warning message!
            </Alert>
        </Snackbar><Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
            <Alert onClose={handleErrorClose} severity="error">
                This is an error message!
            </Alert>
        </Snackbar>
        </Paper>
            </>
    )
}