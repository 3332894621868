import React, {useState} from 'react';
import {Input} from "./index";
import {ClickAwayListener, InputAdornment} from "@material-ui/core";
import {ChromePicker} from "react-color";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {useField} from "formik";

interface Props {
    name: string;
    label: string;
}
const useStyles = makeStyles((theme: Theme) => ({
    colorPicker: {
        position: 'absolute',
        zIndex: 10,
        top: '100%'
    },
    colorSwatch: {
        cursor: 'pointer',
        border: '1px solid black',
        height: 20,
        width: 40
    }
}));

/**
 * Colorized input component for
 * @param props formik props
 * @constructor
 */
export const ColorizedInput:React.FC<Props> = (props) => {
    const classes = useStyles();
    const [field , meta, helpers] = useField(props);
    const { label } = props;
    const [pickerOpen , setPickerOpen] = useState<boolean>(false);

    /**
     * Opens the color picker
     */
    const handleOpenColorPicker = () => setPickerOpen(true);

    /**
     * Closes color picker when clicking outside of the element
     */
    const handleClickAway = () => setPickerOpen(false);

    /**
     * Updates the value when changing from the color picker
     * @param data data from the color picker
     */
    const handleChange = (data: any) => {
        helpers.setValue(data.hex);
    }
    return (
        <div style={{position: 'relative'}}>
            <Input
                label={label}
                name={field.name}
                variant="outlined"
                margin="none"
                fullWidth
                onChange={field.onChange}
                InputProps={{
                    startAdornment: (<InputAdornment position="start" >
                        <div style={{background: field.value}} onClick={handleOpenColorPicker} className={classes.colorSwatch} />
                    </InputAdornment>),
                }}
            />
            {pickerOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <ChromePicker color={field.value} className={classes.colorPicker} onChange={handleChange}/>
                </ClickAwayListener>
                )}
        </div>
    )
}