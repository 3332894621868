import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _reverse from 'lodash/reverse';


// Utils
import { listFilter } from "globalParams";

const searchList = (list:any[], search:string) => {
	search = (search == undefined) ? "" : search.toLowerCase();

	return list.filter((obj:any) => {
		for(let prop in obj) {
			if(obj[prop] != undefined) {
				if(obj[prop].toString().toLowerCase().indexOf(search) > -1) {
					return true;
				}
			}
		}
		return false;
	});
};

const sortList = (list:any[], sort:string, sortDesc:boolean) => {
	return list.sort((a:any, b:any) => {
		let x = (a[sort] == undefined) ? "" : a[sort];
		let y = (b[sort] == undefined) ? "" : b[sort];
	
		if(typeof x == "string") x = x.toLowerCase();
		if(typeof y == "string") y = y.toLowerCase();

		if(sortDesc)
			return ((x > y) ? -1 : ((x < y) ? 1 : 0));
		else
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	});
};


const pagingList = (list:any[], top:number, skip:number = 0) => {
	return list.slice(skip, skip + top);
};

const clientListFilter = (data:any, filter:any) => {
	let newData = {...data, nodes: data.nodes || []};

	// Search
	if(filter.search && filter.search !== '')
		newData.nodes = searchList(newData.nodes || [], filter.search);
	newData.recordCount = newData.nodes.length;

	// Sort
	if(filter.sort && filter.sort !== '') 
		newData.nodes = sortList(newData.nodes || [], filter.sort, filter.sortDesc);

	// Paging
	if(filter.top && filter.top < data.recordCount)
		newData.nodes = pagingList(newData.nodes || [], filter.top, filter.skip);

	newData.pageInfo = _pickBy({
		resultsReturned: newData.nodes.length,
		top: (filter.top > 0) ? filter.top : null,
		skip: (filter.skip > 0) ? filter.skip : null,
		search: (filter.search === '') ? null : filter.search,
		sort: (filter.sort === '') ? null : filter.sort,
		sortDesc: (filter.sortDesc === true) ? true : null,
	});
	return newData;
};


export const WEBSITE_LIST = gql`
	query websites {
		websites {
			recordCount
			pageInfo {
				top
				skip
				sort
				sortDesc
				search
			}
			nodes {
				id
				name
				url
				languageId
				homeRedirect
				loginRedirect
				defaultAppHandle
				defaultMenuId
				isPrivate
				isDefault
				isLogRequest
				isLogResult
				switch
				modified
			}
		}
	}
`;

export const filterReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'skip':
			return { ...state, skip: action.payload }
		case 'top':
			return { ...state, top: action.payload }
		case 'sort':
			return { ...state, sort: action.payload }
		case 'sortDesc':
			return { ...state, sortDesc: action.payload }
		case 'search':
			return { ...state, search: action.payload, skip: 0 }
	}
};

export const useWebsiteList = (filter?: any) => {
	if(filter)
		filter.top = filter.top || listFilter.top;

	const { data, loading, error } = useQuery(WEBSITE_LIST);

	const list = clientListFilter(_get(data, 'websites', {}), filter);

	return { data: list, loading, error };
};
