import { gql } from '@apollo/client';

export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
        user @client {
            id
            userName
            firstName
            lastName
        }
    }
`;
