import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const CatalogIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M9,3 L9,21 L5.941,21 C5.421,21 5,20.513 5,19.913 L5,4.087 C5,3.487 5.421,3 5.941,3 L9,3 z M20,3 L10,3 L10,21 L20,21 C20.52,21 21,20.513 21,19.913 L21,4.087 C21,3.487 20.52,3 20,3 z M4,4 L4,5 L6,5 L6,4 L4,4 z M4,7 L4,8 L6,8 L6,7 L4,7 z M4,10 L4,11 L6,11 L6,10 L4,10 z M4,13 L4,14 L6,14 L6,13 L4,13 z M4,16 L4,17 L6,17 L6,16 L4,16 z M4,19 L4,20 L6,20 L6,19 L4,19 z"></path>
		</SvgIcon>
	);
};

export default CatalogIcon;
