import { gql } from '@apollo/client';

const REFRESH_TOKEN = gql`
    mutation refreshToken($accessToken: String!) {
        refreshToken(accessToken: $accessToken) {
            accessToken {
                token
            }
        }
    }
`;

export { REFRESH_TOKEN };
