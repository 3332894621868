import React, { ComponentProps, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '../../components/FormElements/Input';

interface Props extends ComponentProps<any> {
    isDefault: boolean;
    selectedFont: string | null;
    fontType: string;
    fontLabel: string;
    openFontPicker: (selectedFont: string | null, fontType: string) => void;
    clearSelectedFont: (fontType: string) => void;
}

const useStyles = makeStyles({
    root: {
        marginBottom: '2rem',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    selectedFont: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

/**
 * Font display card
 * @param isDefault Whether this is a default font
 * @param selectedFont Selected font
 * @param fontType type of font (h1, h2, etc)
 * @param openFontPicker Opens font picker
 * @param fontLabel Label to apply to the display
 * @param clearSelectedFont Clears the selected font so we can use default
 */
export const FontTypeCard: React.FC<Props> = ({
    isDefault,
    selectedFont,
    fontType,
    openFontPicker,
    fontLabel,
    clearSelectedFont,
}) => {
    const classes = useStyles();
    const [overrideFont, setOverrideFont] = useState<boolean>(false);
    /**
     * Sends event to parent to open the font picker
     */
    const handleFontPickerOpen = () => {
        openFontPicker(selectedFont, isDefault ? fontType : fontType + 'Font');
    };

    /**
     * Changes the option to override font or not
     */
    const handleChange = () => {
        if (overrideFont) {
            clearSelectedFont(fontType + 'Font');
        }
        setOverrideFont(!overrideFont);
    };

    useEffect(() => {
        if (selectedFont) {
            setOverrideFont(true);
        }
    }, [selectedFont]);
    return (
        <Card className={classes.root} elevation={4}>
            <CardContent>
                <Grid container spacing={2} justify={'space-between'}>
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            style={{ fontFamily: selectedFont || 'Roboto' }}
                        >
                            {fontLabel}
                        </Typography>
                    </Grid>
                    {(isDefault || overrideFont) && (
                        <Grid item>
                            <Typography
                                variant="body2"
                                className={classes.selectedFont}
                                style={{ fontFamily: selectedFont || 'Roboto' }}
                            >
                                {selectedFont || 'No font selected'}
                            </Typography>
                        </Grid>
                    )}

                    {!isDefault && (
                        <Grid item>
                            <Input
                                type="number"
                                label={'Font Size'}
                                name={fontType}
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={2} direction={'column'}>
                    {!isDefault && (
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={overrideFont}
                                        onChange={handleChange}
                                        name="overrideFont"
                                    />
                                }
                                label="Override Font Family"
                            />
                        </Grid>
                    )}
                    {(isDefault || overrideFont) && (
                        <Grid item>
                            <Button
                                size="small"
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleFontPickerOpen}
                            >
                                Change Font
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardActions>
        </Card>
    );
};
