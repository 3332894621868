import React from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiLink from "@material-ui/core/Link";

interface Props {
	to: string;
	underline?: "none" | "hover" | "always" | undefined;
	color?: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined;
	children: any;
	disabled?: boolean;
	className?: string;
};

const Link: React.FC<Props> = ({to, ...props}) => {
	const classes = useStyles(props);
	const location = useLocation();

	if(typeof props.children === "object")
		return (
			<RouterLink
				tabIndex={-1}
				className={`${classes.root} ${props.className}`}
				data-disabled={props.disabled}
				to={{
					pathname: (props.disabled) ? '' : to,
					state: { from: location.pathname }
				}}
			>
				{props.children}
			</RouterLink>
		);

	return (
		<MuiLink component={RouterLink} 
			to={{
				pathname: (props.disabled) ? '' : to,
				state: { from: location.pathname }
			}}
			{...props}
		>
			{props.children}
		</MuiLink>
	);
};

const useStyles = makeStyles((theme:Theme) => ({
	root: {
		textDecoration: 'none',
		color: 'inherit',
		'&[data-disabled="true"]': {
			pointerEvents: 'none',
			'& > *': {
				pointerEvents: 'none',
			},
		},
	},
}));

export default Link;
