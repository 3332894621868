import React from 'react';
import {createStyles, Grid, Paper, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {width: '100%', padding: theme.spacing(2)},

    }),
);

export const TypographySection: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant={'h2'}>Typography</Typography>
            <Paper elevation={4} className={classes.root}>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item>
                        <Typography variant={'h1'} color={"textPrimary"}>
                            Heading 1 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h1'} color={"textSecondary"}>
                            Heading 1 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h2'} color={"textPrimary"}>
                            Heading 2 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h2'} color={"textSecondary"}>
                            Heading 2 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} color={"textPrimary"}>
                            Heading 3 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} color={"textSecondary"}>
                            Heading 3 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h4'} color={"textPrimary"}>
                            Heading 4 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h4'} color={"textSecondary"}>
                            Heading 4 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h5'} color={"textPrimary"}>
                            Heading 5 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h5'} color={"textSecondary"}>
                            Heading 5 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} color={"textPrimary"}>
                            Heading 6 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} color={"textSecondary"}>
                            Heading 6 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body1'} color={"textPrimary"}>
                            Body 1 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body1'} color={"textSecondary"}>
                            Body 1 with secondary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body2'} color={"textPrimary"}>
                            Body 2 with primary text color
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body2'} color={"textSecondary"}>
                            Body 2 with secondary text color
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}