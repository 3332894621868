import React, {useEffect} from 'react';
import FormSection from "../../components/FormElements/FormSection";
import Grid from "@material-ui/core/Grid";
import {ColorizedInput} from "../../components/FormElements/Input/ColorizedInput";
import {createStyles, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ThemeInput} from "../../graphql/queries/themes";
import {FormikProps} from "formik";

interface Props {
    formikProps: FormikProps<ThemeInput>
    signalError: (numErrors: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacer: {
            marginBottom: theme.spacing(2)
        }
    }),
);
export const ColorThemeCustomisation: React.FC<Props> = ({formikProps, signalError}) => {
    const classes = useStyles();
    const inputsOnPage = ['colorPrimary' , 'colorSecondary' , 'textPrimary' , 'textSecondary' , 'error' , 'success' , 'warning' , 'info' , 'background' , 'panelBackground']
    useEffect(() => {
        let errorsOnPageCount = 0;
        Object.keys(formikProps.errors).forEach((key) => {
            if (inputsOnPage.includes(key)) {
                errorsOnPageCount++;
            }
        })
        signalError(errorsOnPageCount);
    }, [formikProps.errors, inputsOnPage, signalError])

    return (
        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <Typography className={classes.spacer} variant={'body2'}>Change the primary and secondary colors of the site</Typography>
                <ColorizedInput name={"colorPrimary"} label={"Primary Color"}/>
                <ColorizedInput name={"colorSecondary"} label={"Secondary Color"}/>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.spacer} variant={'body2'}>Change the action colors of the site</Typography>
                <ColorizedInput name={"success"} label={"Success Color"}/>
                <ColorizedInput name={"info"} label={"Information Color"}/>
                <ColorizedInput name={"warning"} label={"Warning Color"}/>
                <ColorizedInput name={"error"} label={"Error Color"}/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.spacer} variant={'body2'}>Change the background colors of the site</Typography>
                <ColorizedInput name={"background"} label={"Background Color"}/>
                <ColorizedInput name={"panelBackground"} label={"Panel Background Color"}/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.spacer} variant={'body2'}>Change the font colors of the site</Typography>
                <ColorizedInput name={"textPrimary"} label={"Primary Color"}/>
                <ColorizedInput name={"textSecondary"} label={"Secondary Color"}/>
            </Grid>
        </Grid>

    )
}