import React from 'react';
import {Input} from "components/FormElements/Input";

interface Props {}
export const BreakpointThemeCustomisation:React.FC<Props> = () => {
    return (
        <>
            <div>
                <Input
                    type="number"
                    label="Extra Small (Mobile)"
                    name="xsBreakpoint"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                />
                <Input
                    type="number"
                    label="Small (Mobile Landscape)"
                    name="smBreakpoint"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                />
                <Input
                    type="number"
                    label="Medium (Tablet)"
                    name="mdBreakpoint"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                />
                <Input
                    type="number"
                    label="Large (Desktop)"
                    name="lgBreakpoint"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                />
                <Input
                    type="number"
                    label="Extra Large (Large Desktop)"
                    name="xlBreakpoint"
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                />
            </div>
        </>

)
}