import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOverride } from './interfaces/theme';

import set from 'lodash/set';
import merge from 'lodash/merge';

const themeBuilder = (themeObject?: any, options?: ThemeOverride[]) => {
    let option: ThemeOverride[] = options || [];

    let created = option.reduce((prev, curr) => {
        set(prev, curr.name, curr.value);
        return prev;
    }, {} as any);

    let theme:any = {
		props: {
			MuiTypography: {
				variantMapping: {
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
					h6: 'h6',
					subtitle1: 'p',
					subtitle2: 'p',
					body1: 'p',
					body2: 'p',
					button: 'p',
					caption: 'p',
					overline: 'p'
				},
			},
		},
		overrides: {
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiButton: {
				root: {
					fontWeight: 500,
					textTransform: 'none',
				},
				sizeSmall: {
					padding: '2px 8px',
					minWidth: 28,
					fontSize: '1.2rem',
				},
				contained: {
					boxShadow: 'none',
				},
			},
			MuiToolbar: {
				dense: {
					minHeight: 0,
				},
			},
			MuiTextField: {
				root: {
					marginTop: 0,
					marginBottom: 16,
				},
			},
			MuiInputLabel: {
				outlined: {
					transform: 'translate(14px, 16px) scale(1)',
				},
			},
			MuiOutlinedInput: {
				root: {
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: '#2D4E6E',
					},
				},
				input: {
					paddingTop: '14px',
					paddingBottom: '14px',
					backgroundColor: 'white',
				},
			},
			MuiFormLabel: {
				root: {
					color: '#000000bf',
					fontWeight: 500,
				},
			},
			MuiListItem: {
				button: {
					'&:hover': {
						backgroundColor: '#00004412',
					},
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: 34,
				},
			},
			MuiBackdrop: {
				root: {
					backgroundColor: '#00000033',
				},
			},
			MuiTableRow: {
				root: {
					// backgroundColor: '#FAFAFC',
					'&$selected': {
						backgroundColor: '#E9F8F6',
						// '&:hover': {
						// 	backgroundColor: 'red',
						// },
					},
					// '&$hover': {
					// 	'&:hover': {
					// 		backgroundColor: '#F4F4F6',
					// 	},
					// },
					'&:not(.MuiTableRow-head):last-child > .MuiTableCell-root': {
						borderBottomWidth: 0,
					}
				},
			},
			MuiTableCell: {
				root: {
					borderBottom: `1px solid #00000018`,
					paddingLeft: 2,
					paddingRight: 2,
				},
				body: {
					backgroundColor: 'inherit',
				},
				head: {
					paddingTop: 4,
					paddingBottom: 4,
					fontWeight: 500,
					backgroundColor: '',
				},
			},
			MuiDialogTitle: {
				root: {
					padding: '4px 0px 4px 8px',
					backgroundColor: '#2D4E6E',
					position: 'relative',
					'& > .MuiTypography-h6': {
						color: '#FFF',
						fontSize: 13,
						fontWeight: 500,
						'& > .MuiIconButton-root': {
							position: 'absolute',
							top: 0,
							right: 0,
							backgroundColor: '#D32F2F',
							borderRadius: 0,
							padding: 0,
							color: 'white',
							height: 28,
							width: 34,
							'& .MuiSvgIcon-root': {
								fontSize: 18,
							}
						},
					},
				},
			},
			MuiChip: {
				sizeSmall: {
					height: 22,
					lineHeight: 22,
				}
			}
		},
		typography: {
			htmlFontSize: 10,
			h1: 		{ fontSize: '3.0rem', fontWeight: 300 },
			h2: 		{ fontSize: '2.6rem', fontWeight: 300 },
			h3: 		{ fontSize: '2.2rem', fontWeight: 300 },
			h4: 		{ fontSize: '2.0rem', fontWeight: 400 },
			h5: 		{ fontSize: '1.8rem', fontWeight: 400 },
			h6: 		{ fontSize: '1.6rem', fontWeight: 400 },
			subtitle1: 	{ fontSize: '1.4rem', fontWeight: 400 },
			subtitle2: 	{ fontSize: '1.3rem', fontWeight: 400 },
			body1: 		{ fontSize: '1.4rem', fontWeight: 400 },
			body2: 		{ fontSize: '1.3rem', fontWeight: 400 },
			button: 	{ fontSize: '1.4rem', fontWeight: 600 },
			caption: 	{ fontSize: '1.2rem', fontWeight: 400 },
			overline: 	{ fontSize: '1.4rem', fontWeight: 300 },

			fontWeightLight:	300,
			fontWeightMedium:	400,
			fontWeightBold:		800,
			fontWeightRegular:	400,
		},
		shape: {
			borderRadius: 2,
		},
		palette: {
			type: "light",
			/** START
			*	FIXME: Stopgap solution. Resolves issue with missing value causes theme page to fail.
			*		   Investigate into issue why e4cs studio/theme section works without the following 
			*		   section. 
			*/
			theme: {
				main: '#2D4E6E',
				light: '#395D80',
				dark: '#234361',
				contrastText: '#FFFFFF',
			},
			//END
			primary: {
				main: '#2D4E6E',
				light: '#395D80',
				dark: '#234361',
				contrastText: '#FFFFFF',
			},
			secondary: {
				main: '#18BC9C',
				contrastText: '#FFFFFF',
			},
			success: {
				main: '#158915',
				contrastText: '#FFFFFF',
			},
			error: {
				light: '#D35B5B',
				main: '#D32F2F',
				dark: '#A30606',
			},
			background: {
				default: '#FAFAFC',
			},
			grey: {
				"50": "#FAFAFC",
				"100": "#F5F5F9",
				"200": "#EEEEF1",
				"300": "#E0E0E2",
				"400": "#BDBDBF",
				"500": "#9E9EA2",
				"600": "#757577",
				"700": "#616163",
				"800": "#424244",
				"900": "#212123",
				"A100": "#D5D5D7",
				"A200": "#AAAAAC",
				"A400": "#303032",
				"A700": "#616163",
			},
			action: {
				selected: '#0000440F',
			},
		},
    };
    let mergedTheme = merge({}, theme, created);
    return createMuiTheme(mergedTheme);
};

export default themeBuilder;
