import React, {useEffect, useState} from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {
    Drawer, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,

} from "@material-ui/core";
import {Font} from "../../../graphql/queries/themes";
import {green} from "@material-ui/core/colors";

interface Props {
    fonts: Font[] | undefined
    selectedFont: string | null;
    drawerWidth: number;
    open: boolean;
    handleFontSelect: (fontFamily: string) => void;
    handleFontPickerClose: () => void;
}

/**
 * Selects fonts to use from google fonts
 * @param props
 * @constructor
 */
export const FontSelector: React.FC<Props> = (props) => {
    const {fonts, drawerWidth, selectedFont, open, handleFontSelect, handleFontPickerClose} = props;

    if (!fonts) {
        return <p>Loading Fonts</p>
    }
    return (
        <Drawer open={open} style={{width: drawerWidth}} anchor={'right'} onClose={handleFontPickerClose}>
            <List>
                {fonts.map(font => (
                    <ListItem key={font.family} button onClick={() => handleFontSelect(font.family)}
                              selected={font.family === selectedFont}>
                        <ListItemText primary={font.family}
                                      primaryTypographyProps={{style: {fontFamily: font.family}}}/>
                        {font.family === selectedFont && <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="selected">
                                <CheckCircleRoundedIcon style={{color: green[500]}}/>
                            </IconButton>
                        </ListItemSecondaryAction>}
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}