import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Material-UI
import { makeStyles, Theme, fade } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
	icon: any;
	url: string;
	hash?: string;
	name: string;
};

const AppMenuItem: React.FC<Props> = (props) => {
	const classes = useStyles();
	const location = useLocation();

	const [selected, setSelected] = useState(false);

	useEffect(() => {
		const hashIndex = location.hash.indexOf('/', 2);
		const hash = (hashIndex === -1) ? location.hash : location.hash.substr(0, hashIndex);
		const propsHash = (props.hash) ? `#${props.hash}` : "";
		const app = location.pathname.split('/')[1];

		setSelected(props.url + propsHash === `/${app}${hash}`);
	},[props.url, props.hash, location]);

	const handleClick = () => {
		if(props.url.indexOf('#') !== -1) {
			window.location.hash = props.url;
		}
	};

	return (
		<Link component={RouterLink} to={{ pathname: props.url, hash: props.hash || "" }} underline="none" variant="body2" className={classes.link} onClick={handleClick}>
			<ListItem button data-selected={selected}>
				<ListItemIcon>{props.icon}</ListItemIcon>
				<ListItemText primary={props.name} />
			</ListItem>
		</Link>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	link: {
		'& > .MuiListItem-root': {
			borderRadius: theme.shape.borderRadius,
			padding: theme.spacing(0, 0, 0, 1),
			'& > .MuiListItemIcon-root': {
				minWidth: 30,
				color: '#0000025c',
				marginTop: -2,
				'& > .MuiSvgIcon-root': {
					fontSize: `2rem`,
				},
			},
			'& > .MuiListItemText-root': {
				color: theme.palette.grey[800],
				'& > .MuiTypography-displayBlock': {
					
				},
			},
			'&[data-selected="true"]': {
				backgroundColor: fade(theme.palette.primary.main, 0.1),
				'& > .MuiListItemText-root': {
					color: theme.palette.primary.main,
					'& > .MuiTypography-displayBlock': {
						fontWeight: 500,
					},
				},
				'& > .MuiListItemIcon-root': {
					color: theme.palette.primary.main,
				},
			},
			'&:not([data-selected="true"]):hover': {
				backgroundColor: fade(theme.palette.primary.main, 0.03),
				'& > .MuiListItemText-root': {
					color: theme.palette.primary.main,
				},
				'& > .MuiListItemIcon-root': {
					color: theme.palette.primary.main,
				},
			},
		},
	},
}));

export default AppMenuItem;
