import React from 'react';

// Custom-Mui
import Link from 'customMui/Link';

// Icons
import HomeIcon from '@material-ui/icons/Home';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';

interface Props {
	breadcrumbs: any;
	className?: string;
	style?: string;
};

const Breadcrumbs: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<MuiBreadcrumbs className={`${classes.breadcrumbs} ${props.className || ''}`.trim()}>
			<Link to="/"><IconButton size="small" color="default"><HomeIcon className={classes.home}/></IconButton></Link>
			{props.breadcrumbs?.map((obj:any) => {
				if(obj.url)
					return <Link key={`${obj.label}|${obj.url}`} to={obj.url} color="textSecondary">{obj.label}</Link>;
				else
					return <Typography key={`${obj.label}|${obj.url}`} color="textSecondary" className={classes.unlink}>{obj.label}</Typography>;
			})}
		</MuiBreadcrumbs>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	breadcrumbs: {
		fontSize: 12,
		fontWeight: 500,
	},
	home: {
		fontSize: 16,
		marginTop: -2,
	},
	unlink: {
		fontSize: 'inherit',
		fontWeight: 'inherit',
	}
}));

export default Breadcrumbs;
