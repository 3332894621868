import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';

const GET_ORDER_DETAILS_QUERY = gql`
  query GET_ORDERDETAILS($id: ID!) {
    getOrderById(id: $id) {
      id
      orderNumber
      orderDate
      orderStatus
      itemCount
      subTotal
      freightTotal
      taxTotal
      total
      lines {
        id
        productId
        productName
        productCode
        productEntityCode
        quantity
        price
        img {
          id
          alt
          cdnURL
        }
        selectedDimensions {
          id
          name
          value
        }
        lineProductCode
        lineProductName
      }
      addresses {
        id
        address1
        address2
        city
        stateName
        addressTypeName
        countryName
        postalCode
      }
    }
  }
`;

export const useGetOrderDetail = (orderId: string) => {
  const { data, loading, error } = useQuery(GET_ORDER_DETAILS_QUERY, {
    variables: {
      id: orderId
    }
  });



	const list = _get(data, 'getOrderById', []);


  return { data: list, loading, error };
};

