import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const ProductIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M12,2 C14.922,2.111 16.719,4.215 17,7 L21,7 L22,22 L2,22 L3,7 L7,7 C7.111,4.077 9.215,2.28 12,2 z M9,9 L7,9 C7,11.76 9.24,14 12,14 C14.76,14 17,11.76 17,9 L15,9 C15,10.66 13.66,12 12,12 C10.34,12 9,10.66 9,9 z M12,4 C10.34,4 9,5.34 9,7 L15,7 C15,5.34 13.66,4 12,4 z"></path>
		</SvgIcon>
	);
};

export default ProductIcon;
