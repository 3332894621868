import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { getToken, tokenKey, isTokenValid } from 'services/auth.service';
// import { store } from 'shared/store';
// import { API_REQUEST_FAILED } from 'shared/actions/global.actions';
import { history } from './../App';

declare global {
	interface Window {
		refreshingToken: boolean;
	}
}

const _getToken = () => {
	return new Promise((resolve, reject) => {
		// Currently Refreshing...
		if (window.refreshingToken) {
			let count = 0;
			let interval = setInterval(() => {
				if (!window.refreshingToken) {
					let token = sessionStorage.getItem(tokenKey);
					// *** RESOLVE ***
					resolve(token);
					clearInterval(interval);
				}
				count++;
				if (count >= 60) {
					reject('Refresh token timed out');
					clearInterval(interval);
				}
			}, 500);
		} else {
			try {
				let currentToken = getToken();
				if (currentToken === undefined || currentToken === null)
					// eslint-disable-next-line no-throw-literal
					throw 'Token not found. User may not be signed in.';

				let token = currentToken || '';
				//let now = new Date().getTime();
				// let decodedToken = decodeToken(token);
				// Fresh
				if (isTokenValid()) {
					// *** RESOLVE ***
					resolve(token);
				}
				// Expired
				else {
					window.refreshingToken = true;
					sessionStorage.setItem(tokenKey, 'Refreshing...');

					return _refreshToken(currentToken).then(
						(newToken: string) => {
							// newToken = this._buildToken(newToken);
							sessionStorage.setItem(
								tokenKey,
								JSON.stringify(newToken)
							);

							window.refreshingToken = false;
							// *** RESOLVE ***
							resolve(newToken);
						}
					);
				}
			} catch (error) {
				throw error;
			}
		}
	});
};

export function fetchStatusHandler(response: AxiosResponse) {
	if (response.status >= 200 && response.status < 400) {
		return response;
	}

	// Show toast message when error occurs
	// if (response.status >= 400 && response.status != 401) {
	// 	store.dispatch({
	// 		type: API_REQUEST_FAILED,
	// 		payload: {
	// 			message: `Something went wrong. ${response.statusText}`
	// 		}
	// 	});
	// }

	throw new Error(response.statusText);
}

export const accessTokenInterceptor = async (
	config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	let token = null;
	try {
		token = await _getToken();
	} catch (error) {
		// Redirect to login page no need to do anything.
	}

	if (token != null) {
		config.headers = config.headers || {};
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
};

const _refreshToken = (token: string) => {
	let options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			'Content-Length': token.length,
		},
		body: token,
	};
	return axios
		.post('/api/v1/refreshToken', options)
		.then((response) => {
			return response;
		})
		.catch((error: any) => {
			return error;
		});
};

export const responseRefreshTokenInterceptor = (error: AxiosError) => {
	const originalRequest: Partial<AxiosRequestConfig & { _retry: boolean }> =
		error.config;
	// const serverCallUrl = new URL(originalRequest.url || '');
	const status = error.response && error.response.status;
	const headers = error.request.headers;

	// TODO: Check if we have refresh token
	if (
		'token-expired' in headers &&
		headers['token-expired'] === 'true' &&
		status === 401 &&
		!originalRequest._retry
	) {
		// Try to refresh the token
		//let token = await refreshAccessToken();
		//setAccessToken()
		// Redirect to login page
		history.push('/login');
		//originalRequest._retry = true;
		//originalRequest.headers.Authorization = `Bearer ${token}`;

		//return axios(originalRequest);
	}

	return Promise.reject(error);
};
