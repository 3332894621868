import React from 'react';

// Icons
import ErrorRobotIcon from 'icons/ErrorRobotIcon';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {}

const ErrorRobot: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.message}>
				<ErrorRobotIcon className={classes.icon}/>
				<div>
					<Typography variant="h2" color="inherit" gutterBottom>Oops, something went wrong!</Typography>
					<Typography variant="subtitle1" color="inherit">Sorry for any inconvenience this may cause.  We will try to get this page back up and running as soon as possible.</Typography>
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'grid',
		placeItems: 'center',
	},
	message: {
		display: 'flex',
		opacity: 0.7,
		maxWidth: 500,
	},
	icon: {
		fontSize: 80,
		marginRight: theme.spacing(2),
	},
}));

export default ErrorRobot;
