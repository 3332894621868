import React from 'react';
import { useField, ErrorMessage } from 'formik';

// Material-UI
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

interface Props {
    name: string;
    label: string;
    [key: string]: any;
}

export const Input = ({ label, ...props }: Props) => {
	const [field, meta] = useField(props);

	if(props.type === 'checkbox')
		return (<>
			<FormControlLabel
				label={label}
				{...field}
				{...props}
				control={<Checkbox checked={field.value} {...field} {...props} />}
			/>
			<FormHelperText><ErrorMessage name={field.name} /></FormHelperText>
		</>);

	return (
		<TextField
			label={label}
			{...field}
			{...props}
			InputLabelProps={{ shrink: true }}
			error={!!meta.touched && !!meta.error}
			helperText={<ErrorMessage name={field.name} />}
		/>
	);
};
