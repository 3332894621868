import _get from 'lodash/get';


const mergeList = (array1:any[] = [], array2:any[] = [], key:string = '__ref') => {
	let list = [...array1];
	for(let i = 0; i < array2.length; i++) {
		const index = array1.findIndex((obj:any) => _get(obj, key) === _get(array2[i], key));
		if(index === -1)
			list.push(array2[i]);
	}
	return list;
};


export const offsetLimitPagination = (keyArgs:any[]|false = false) => {
	return {
		keyArgs,
		read(existing:any) {
			if(!existing) return;

			return {
				...existing,
				nodes: existing.nodes.slice(existing?.pageInfo?.skip, existing?.pageInfo?.skip + existing?.pageInfo?.top),
			};
		},
		merge(existing = { nodes: [] }, incoming:any) {
			return {
				...incoming,
				nodes: [...mergeList(existing.nodes, incoming.nodes)]
			};
		}
	}
};
