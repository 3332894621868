//PageHeader
import React from 'react';

// Components
import Breadcrumbs from 'components/AppElements/Breadcrumbs';

// Icons
import BackIcon from '@material-ui/icons/ArrowBack';

// Custom-Mui
import Link from 'customMui/Link';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

interface Props {
	name: string;
	maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
	backUrl?: string;
	endAdornment?: any;
	breadcrumbs?: any;
	margin?: number;
};


const PageHeader: React.FC<Props> = (props) => {
	const classes = useStyles(props);

	return (
		<>
			<Container maxWidth={props.maxWidth || "md"}>
				{props.breadcrumbs && (
					<Breadcrumbs breadcrumbs={props.breadcrumbs} className={classes.breadcrumbs}/>
				)}
				<Toolbar disableGutters>
					{props.backUrl && (
						<Link to={props.backUrl}>
							<IconButton className={classes.back}><BackIcon/></IconButton>
						</Link>
					)}
					<div className="grow">
						<Typography variant="h1" display="block" className={classes.heading}>{props.name}</Typography>

						<div className={classes.children}>{props.children}</div>
					</div>
					{props.endAdornment}
				</Toolbar>
				
			</Container>
			<Divider className={classes.divider}/>
		</>
	);
};

const useStyles = makeStyles<Theme, Props>(theme => ({
	divider: {
		marginBottom: props => theme.spacing((props.margin === undefined) ? 2 : props.margin),
	},
	breadcrumbs: {
		margin: theme.spacing(0.5, 0, -0.5, 0),
	},
	back: {
		marginLeft: theme.spacing(-0.5),
	},
	heading: {
		marginBottom: theme.spacing(-0.5),
	},
	children: {
		flex: '1 0 0px',
		display: 'flex',
		paddingBottom: theme.spacing(0.5),
		'& > *': {
			flex: '1 1 0px',
		}
	},
}));

export default PageHeader;
