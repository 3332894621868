import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Header from 'components/Layout/Header';
import AppMenu from 'components/Layout/AppMenu';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { Toolbar } from '@material-ui/core';

interface Props {
	children: React.ReactNode;
}

const DefaultLayout: React.FC<Props> = (props) => {
	const classes = useStyles();
	const location = useLocation();

	const [drawerOpen, setDrawerOpen] = useState(false);

	useEffect(() => {
		setDrawerOpen(false);
	}, [location]);

	return (
		<div className={classes.root}>
			<Header>
				<IconButton color="inherit" aria-label="open drawer" onClick={() => setDrawerOpen(value => !value)}><MenuIcon /></IconButton>
			</Header>

			<div className={classes.body}>
				<Hidden smDown>
					<div className={classes.drawer}>
						<div className={classes.scroll}>
							<AppMenu/>
						</div>
					</div>
				</Hidden>

				<main className={classes.main}>
					<div className={classes.scroll}>
						{props.children}
					</div>
				</main>
			</div>

			<Drawer open={drawerOpen} elevation={2} onClose={() => setDrawerOpen(false)}>

				<Toolbar variant="dense" className={classes.logoBar}>
					<img alt="logo" src="/logo-white.svg" className={classes.logo} />
					<div className="grow"></div>
					<IconButton edge="end" color="inherit" onClick={() => setDrawerOpen(false)}><CloseIcon/></IconButton>
				</Toolbar>

				<div className={classes.drawer}>
					<div className={classes.scroll}>
						<AppMenu/>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
	},
	body: {
		flex: '1 0 0px',
		display: 'flex',
	},
	drawer: {
		width: 240,
		display: 'flex',
		flexDirection: 'column',
		borderRight: `1px solid ${theme.palette.divider}`,
		[theme.breakpoints.down('sm')]: {
			flex: '1 0 0px',
			width: 300,
			maxWidth: '95vw',
			borderRightWidth: 0,
		},
	},
	main: {
		flex: '1 0 0px',
		display: 'flex',
		flexDirection: 'column',
	},
	logoBar: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	logo: {
		maxHeight: 40,
	},
	scroll: {
		flex: '1 0 0px',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default DefaultLayout;
