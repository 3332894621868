import React from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
	fullscreen: boolean;
}

const Loading: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root} data-full={props.fullscreen}>
			<CircularProgress color="secondary" />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		display: 'grid',
		placeItems: 'center',
		'&[data-full]': {
			height: '100vh',
			width: '100vw',
		}
	},
}));

export default Loading;
