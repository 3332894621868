import React, { useState } from 'react';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Material-UI
import { makeStyles, Theme, fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

interface Props {}

const HeaderSearch: React.FC<Props> = (props) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const open = Boolean(anchorEl);
	const id = open ? 'search-popover' : undefined;

	return (
		<div className={classes.container}>
			<div className={classes.searchField}>
				<div className={classes.search}>
					<div className={classes.searchIcon}>
						<SearchIcon fontSize="small" />
					</div>
					<InputBase placeholder="Search…" classes={{ root: classes.inputRoot, input: classes.inputInput }} inputProps={{ 'aria-label': 'search' }} onFocus={e => setAnchorEl(e.currentTarget)} />
				</div>

				{open && (
					<React.Fragment>
						<Backdrop open={true} onClick={() => setAnchorEl(null)}/>
						<Popper id={id} open={true} anchorEl={anchorEl} placement="bottom" transition>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper className={classes.searchDropdown}>
										<SearchIcon className={classes.emptySearchIcon} color="disabled" />
									</Paper>
								</Fade>
							)}
						</Popper>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		flex: '1 0 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// margin: theme.spacing(0, 1),
		// [theme.breakpoints.down('xs')]: {
		// 	marginLeft: 0,
		// },
	},
	searchField: {
		flexGrow: 1,
		maxWidth: 700,
		position: 'relative',
	},
	search: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.1),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.2),
		},
		'&:focus-within': {
			backgroundColor: fade(theme.palette.common.white, 1),
			color: fade(theme.palette.text.secondary, 1),
			boxShadow: theme.shadows[2],
		},
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 1),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: 35,
		width: '100%',
		'&:focus': {
			color: theme.palette.text.primary,
		},
	},
	searchDropdown: {
		marginTop: 6,
		marginLeft: 6,
		display: 'grid',
		placeItems: 'center',
		width: 'calc(100vw - 24px)',
		height: '85vh',
		[theme.breakpoints.up('sm')]: {
			marginLeft: 0,
			width: '100rem',
			maxWidth: 700,
			minHeight: 300,
			maxHeight: 600,
		}
	},
	emptySearchIcon: {
		fontSize: '13rem',
		opacity: 0.2,
	}
}));

export default HeaderSearch;
