import React from "react";

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';

interface Props {
	elevation?: number;
	square?: boolean;
	variant?: 'elevation' | 'outlined';
	padding?: number;
	marginBottom?: number;
	style?: object;
	className?: string;
};

const Paper: React.FC<Props> = (props) => {
	const classes = useStyles(props);
	const { marginBottom, padding, ...otherProps } = props;

	return (
		<MuiPaper className={`${classes.root}${' '+ props.className || ''}`} {...otherProps}>
			{props.children}
		</MuiPaper>
	);
};

const useStyles = makeStyles<Theme, Props>(theme => ({
	root: {
		padding: props => theme.spacing(props.padding || 0),
		marginBottom: props => theme.spacing(props.marginBottom || 0),
	},
}));

export default Paper;
