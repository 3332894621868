import React from 'react';
import {Button, createStyles, Grid, Paper, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {width: '100%', padding: theme.spacing(2)},

    }),
);
export const ButtonSection: React.FC = () => {

    const classes = useStyles();
    return (
        <>
            <Typography variant={'h2'}>Buttons</Typography>
            <Paper elevation={4} className={classes.root}>
                <Grid container spacing={2} justify={'center'}>
                    <Grid item>
                        <Button color={'primary'}>Primary</Button>
                    </Grid>
                    <Grid item>
                        <Button color={'secondary'}>Secondary</Button>
                    </Grid>
                    <Grid item>
                        <Button color={'primary'} variant={"contained"}>Primary Contained</Button>
                    </Grid>
                    <Grid item>
                        <Button color={'secondary'} variant={'contained'}>Secondary Contained</Button>
                    </Grid>
                    <Grid item>
                        <Button color={'primary'} variant={'outlined'}>Primary Outlined</Button>
                    </Grid>
                    <Grid item>
                        <Button color={'secondary'} variant={'outlined'}>Secondary Outlined</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}